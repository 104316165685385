<template>
    <footer>
      <div class="footer">
        <div class="footer-logo logo footer-item">
          <img src="../assets/logo_transparent_small.png" alt="IVIS LOGO">
          <h2>Intelligent Vision Labs</h2>
        </div>
        <div class="navigation footer-item">
          <ul>
            <li><router-link to="/">Home</router-link></li>
            <li><router-link to="/solutions">Our Solutions</router-link></li>
            <li><router-link to="/aboutus">About Us</router-link></li>
            <li><router-link to="/products">Our Products</router-link></li>
            <li><router-link to="/ourteam">Our Team</router-link></li>
          </ul>
        </div>
        <div class="contact-div footer-item">
        <div class="contacts">
          <div class="ph-image"><img src="../assets/phone.svg" alt="Phone Img" width="20"></div>
          <div class="num"><a href="tel:+919449963312">+91 9449963312</a></div>
        </div>
        <div class="location footer-item">
          <div class="loc-image"><img src="../assets/location.svg" alt="location img" width="20"></div>
          <div class="location-text"><a href="https://www.google.com/maps/place/IVIS+LABS+Pvt+Ltd/@12.318221,76.6225487,17z/data=!3m1!4b1!4m6!3m5!1s0x3baf7b8e751cfd97:0x4ceec21d710bb532!8m2!3d12.318221!4d76.6251236!16s%2Fg%2F11qz4v2ys1?entry=ttu&g_ep=EgoyMDI0MTEwNS4wIKXMDSoASAFQAw%3D%3D" target="_blank" rel="noopener noreferrer">113/A, 1st floor, 7th cross, Temple road, Jayalakshmipuram, Mysuru -570012</a></div>
        </div>
      </div>
      <div class="socials footer-item">
        <ul class="example-2">
          <li class="icon-content">
            <a href="https://www.linkedin.com/company/ivislabs/" aria-label="LinkedIn" data-social="linkedin">
              <div class="filled"></div>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-linkedin"
                viewBox="0 0 16 16" xml:space="preserve">
                <path
                  d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z"
                  fill="currentColor"></path>
              </svg>
            </a>
            <div class="tooltip">LinkedIn</div>
          </li>
          <!-- <li class="icon-content">
            <a href="https://www.github.com/" aria-label="GitHub" data-social="github">
              <div class="filled"></div>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-github"
                viewBox="0 0 16 16" xml:space="preserve">
                <path
                  d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27s1.36.09 2 .27c1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.01 8.01 0 0 0 16 8c0-4.42-3.58-8-8-8"
                  fill="currentColor"></path>
              </svg>
            </a>
            <div class="tooltip">GitHub</div> -->
          <!-- </li> -->
          <li class="icon-content">
            <a href="https://www.instagram.com/ivislabs?igsh=ZDJ1ejE2YjU0eGhp" aria-label="Instagram"
              data-social="instagram">
              <div class="filled"></div>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-instagram"
                viewBox="0 0 16 16" xml:space="preserve">
                <path
                  d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334"
                  fill="currentColor"></path>
              </svg>
            </a>
            <div class="tooltip">Instagram</div>
          </li>
          <li class="icon-content">
            <a href="mailto:contact@ivislabs.com" aria-label="Youtube" data-social="gmail">
              <div class="filled"></div>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-outlook"
                viewBox="0 0 32 32" xml:space="preserve">
                <path
                  d="M19.484,7.937v5.477L21.4,14.619a.489.489,0,0,0,.21,0l8.238-5.554a1.174,1.174,0,0,0-.959-1.128Z M19.484,15.457l1.747,1.2a.522.522,0,0,0,.543,0c-.3.181,8.073-5.378,8.073-5.378V21.345a1.408,1.408,0,0,1-1.49,1.555H19.483V15.457Z M10.44,12.932a1.609,1.609,0,0,0-1.42.838,4.131,4.131,0,0,0-.526,2.218A4.05,4.05,0,0,0,9.02,18.2a1.6,1.6,0,0,0,2.771.022,4.014,4.014,0,0,0,.515-2.2,4.369,4.369,0,0,0-.5-2.281A1.536,1.536,0,0,0,10.44,12.932Z M2.153,5.155V26.582L18.453,30V2ZM13.061,19.491a3.231,3.231,0,0,1-2.7,1.361,3.19,3.19,0,0,1-2.64-1.318A5.459,5.459,0,0,1,6.706,16.1a5.868,5.868,0,0,1,1.036-3.616A3.267,3.267,0,0,1,10.486,11.1a3.116,3.116,0,0,1,2.61,1.321,5.639,5.639,0,0,1,1,3.484A5.763,5.763,0,0,1,13.061,19.491Z"
                  fill="currentColor"></path>
              </svg>
            </a>
            <div class="tooltip">Mail</div>
          </li>
        </ul>
      </div>
    </div>
    <div class="rights">
      <p>&copy; 2024 IVIS LABS Private Limited | All Rights Reserved</p>
    </div>
  </footer>
  </template>
  
  <script>
  export default {
    name: 'FooTer'
  }
  </script>
  
  <style scoped>

  .logo img {
    width: 100px;
    margin-left: .5rem;
    cursor: pointer;
  }
    footer{
  position: relative;
  bottom: 0;  
  background-color: var(--primary-color);
  box-shadow: 10px 10px 50px lightblue inset;
  padding: 1rem 1rem 1rem;
}

.footer {
    padding: 1rem 1rem 1rem;
  display: grid;
  align-items: center;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
  min-height: 10vw;
}

.footer a,
 .footer router-link{
  text-decoration: none;
  color: var(--secondary-color);
  cursor: pointer;
}

.footer-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 0.8rem;
}

.footer-logo h2{
  color: #2ebfc1;
  text-align: center;
}

.navigation ul{
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  justify-content: center;
  align-items: center;
}

.contact-div {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  justify-content: center;
}

.contacts {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 15px;
}

.location {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.location-text{
  text-align: center;
  color: var(--secondary-color);
}

.rights {
  text-align: center;
  color: var(--secondary-color);
}

.example-2 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}
.example-2 .icon-content {
  margin: 0 10px;
  position: relative;
}
.example-2 .icon-content .tooltip {
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  padding: 6px 10px;
  border-radius: 5px;
  opacity: 0;
  visibility: hidden;
  font-size: 14px;
  transition: all 0.3s ease;
}
.example-2 .icon-content:hover .tooltip {
  opacity: 1;
  visibility: visible;
  top: 60px;
}
.example-2 .icon-content a {
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  color: #4d4d4d;
  background-color: #fff;
  transition: all 0.3s ease-in-out;
}
.example-2 .icon-content a:hover {
  box-shadow: 3px 2px 45px 0px rgb(0 0 0 / 12%);
}
.example-2 .icon-content a svg {
  position: relative;
  z-index: 1;
  width: 30px;
  height: 30px;
}
.example-2 .icon-content a:hover {
  color: white;
}

.example-2 .icon-content a .filled {
  position: absolute;
  top: auto;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-color: #000;
  transition: all 0.3s ease-in-out;
}
.example-2 .icon-content a:hover .filled {
  height: 100%;
}

.example-2 .icon-content a[data-social="linkedin"] .filled,
.example-2 .icon-content a[data-social="linkedin"] ~ .tooltip {
  background-color: #0274b3;
}

.example-2 .icon-content a[data-social="github"] .filled,
.example-2 .icon-content a[data-social="github"] ~ .tooltip {
  background-color: #24262a;
}
.example-2 .icon-content a[data-social="instagram"] .filled,
.example-2 .icon-content a[data-social="instagram"] ~ .tooltip {
  background: linear-gradient(
    45deg,
    #405de6,
    #5b51db,
    #b33ab4,
    #c135b4,
    #e1306c,
    #fd1f1f
  );
}
.example-2 .icon-content a[data-social="WhatsApp"] .filled,
.example-2 .icon-content a[data-social="WhatsApp"] ~ .tooltip {
  background-color: #25d366;
}

.example-2 .icon-content a[data-social="gmail"] .filled,
.example-2 .icon-content a[data-social="gmail"] ~ .tooltip {
  background-color: #0078d4;
}




@media (max-width:860px) {
  .footer {
    grid-template-columns: repeat(2, 1fr);
    padding: 1rem;
}

.services {
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto auto;
}

.card-4, .card-5 {
  width: 26vw;
}

.card-1 { grid-area: 1 / 1 / 2 / 2; }
.card-2 { grid-area: 1 / 2 / 2 / 3; }
.card-3 { grid-area: 1 / 3 / 2 / 4; }
.card-4 { grid-area: 2 / 1 / 3 / 3; }
.card-5 { grid-area: 2 / 2 / 3 / 4; }

}

@media (max-width:725px) {
  .head {
    letter-spacing: 0;
  }

  .footer-item{
    padding: 0.8rem;
  }
}

@media (max-width:570px) {
  .head {
    font-size: 3rem;
  }

  .card-1, .card-2, .card-3 {
    margin: 0.5rem;
  }

  .buttons{
    flex-direction: column-reverse;
  }

  .buttons a button {
    min-width: 235px;
  }  

  .footer {
    grid-template-columns: 1fr;
  }

  .footer-item{
    padding: 0.5rem;
  }
}

@media (max-width:490px){
  .services {
    display: flex;
    flex-direction: column;
  }

  .card-1, .card-2, .card-3, .card-4, .card-5 {
    width: 35vh;
  }

  .card {
    height: 40vh;
  }
}

@media (max-width:390px) {
  .head {
    font-size: 2rem;
  }
}

@media (min-height:950px) {
  .main, .bg-box, .text{
    height: 50vh;
  }
}
  </style>